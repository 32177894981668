<template>
  <CreateLeadModal
    :defaultSourceId="sourceIdFromParentPage"
    :selectedLeadId="selectedLeadId"
    @fetchData="fetchData"
  />
  <div>
    <div class="modal my-4" id="exampleModal2" aria-labelledby="exampleModalLabel">
      <div class="LeadDetaisForm">
        <div class="modal-dialog modal-dialog-scrollable modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <div class="row col-md-12">
                <div class="col-md-6 d-flex align-content-center">
                  <img
                    src="../../assets/icons/profile.png"
                    alt=""
                    style="width: 45px; height: 45px"
                  />
                  <div class="ms-3" v-if="leadData">
                    <h5 class="card-title m-0 p-0">{{ leadData.name }}</h5>
                    <h6 class="card-subtitle mb-2 text-muted m-0 p-0">
                      {{ leadData.email }}
                    </h6>
                    <h6 class="card-subtitle mb-2 text-muted m-0 p-0">
                      {{ leadData.contactNo }}
                    </h6>
                    <!-- First block -->
                    <h6
                      class="card-subtitle mb-2 text-muted m-0 p-0"
                      v-if="leadData['leadAddresses.address']"
                    >
                      {{ leadData["leadAddresses.address"] }}
                      {{ leadData["leadAddresses.city"] }}
                      {{ leadData["leadAddresses.state"] }}
                    </h6>

                    <!-- Second block -->
                    <h6
                      class="card-subtitle mb-2 text-muted m-0 p-0"
                      v-if="leadData['addresses.address']"
                    >
                      {{ leadData["addresses.address"] }}
                      {{ leadData["addresses.city"] }}
                      {{ leadData["addresses.state"] }}
                    </h6>
                  </div>
                </div>
                <div class="col-md-1"></div>
                <div
                  class="col-md-5 d-flex align-content-start justify-content-around text-nowrap headerbuttons"
                  style="font-size: 14px; padding-left: 50px"
                >
                  <div class="position-relative">
                    <button
                      class="btn btn-sm me-1"
                      style="letter-spacing: 2px; font-size: 12px; cursor: pointer"
                      :class="priorityButtonClass"
                      v-if="leadData.priority"
                    >
                      {{ leadData.priority }}
                    </button>
                  </div>
                  <div>
                    <button
                      class="btn btn-outline-secondary btn-sm me-1"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal1"
                      style="letter-spacing: 2px; font-size: 12px; cursor: pointer"
                    >
                      Edit
                    </button>
                  </div>

                  <div class="position-relative">
                    <select
                      @change="
                        changeStatus($event.target.value, leadData.estimateApprovalStatus)
                      "
                      class=""
                      v-model="leadData.status"
                      style="cursor: pointer"
                    >
                      <option
                        v-for="(action, index) in actions"
                        :key="index"
                        :value="action"
                        class="bg-light text-dark btn btn-sm"
                      >
                        {{ action }}
                      </option>
                    </select>

                    <div v-if="showTransactionDetails" class="transaction-details">
                      <div class="card">
                        <div class="card-header" style="font-weight: 500">
                          Enter Transaction Details
                          <button
                            type="button"
                            class="btn-close"
                            @click="hideTransactionDetails"
                          ></button>
                        </div>
                        <div class="card-body">
                          <form>
                            <div class="form-group">
                              <label for="transactionDetails" style="font-size: 14px"
                                >Payment Transaction Details:</label
                              >
                              <textarea
                                class="form-control border-black"
                                id="transactionDetails"
                                v-model="transactionDetails"
                                rows="4"
                              ></textarea>
                            </div>
                          </form>
                          <div
                            class="d-flex justify-content-center align-content-center m-1 mt-3"
                          >
                            <button
                              type="submit"
                              class="btn btn-sm btn-primary ms-2"
                              @click="updateLeadStatus"
                            >
                              Confirm
                            </button>
                            <button
                              type="button"
                              @click="hideTransactionDetails"
                              class="btn btn-sm btn-secondary ms-2"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <img
                    src="../../assets/icons/cancel.png"
                    alt=""
                    style="
                      width: 20px;
                      height: 20px;
                      position: absolute;
                      right: 10px;
                      top: 2.5%;
                      cursor: pointer;
                    "
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
              </div>
            </div>

            <div class="modal-body">
              <section class="subHeadingsOfLeads">
                <div
                  class="p-1 mt-2 mb-1 subHeaderHeadingBgColor tableHeaderHeading text-nowrap subHeadingsOfLead"
                  style="border-radius: 5px"
                >
                  <p
                    :class="{
                      'active-SubHeading': showOverview,
                      'inactive-SubHeading': !showOverview,
                    }"
                    @click="showDetails('Overview')"
                  >
                    Details
                  </p>
                  <p
                    :class="{
                      'active-SubHeading': showEstimate,
                      'inactive-SubHeading': !showEstimate,
                    }"
                    @click="showDetails('Estimate')"
                  >
                    Estimates
                  </p>
                  <p
                    :class="{
                      'active-SubHeading': showAllComments,
                      'inactive-SubHeading': !showAllComments,
                    }"
                    @click="showDetails('Comments')"
                  >
                    Comments
                  </p>
                  <p
                    :class="{
                      'active-SubHeading': showAllActivity,
                      'inactive-SubHeading': !showAllActivity,
                    }"
                    @click="showDetails('AllActivity')"
                  >
                    All Activities
                  </p>
                </div>
                <div class="subSectionOfLeads col-md-12">
                  <div v-show="showOverview" class="sectionOther col-md-12">
                    <div class="d-flex flex-column">
                      <div>
                        <h5
                          class="mt-2 mb-2"
                          :class="{
                            'subHeadingHeader-active': showAddressDetails,
                            'subHeadingHeader-inactive': !showAddressDetails,
                          }"
                          @click="showOverviewDetails('address')"
                        >
                          <section class="floor-plan-container">
                            <span class="blue-dot"></span>
                            <span class="text-decoration-underline">Address</span>
                          </section>
                        </h5>

                        <div v-show="showAddressDetails" class="row">
                          <div class="col-md-6">
                            <h5 class="purpleText">Billing Address</h5>
                            <div class="col-md-12">
                              <div class="card AddressCard">
                                <div class="card-body">
                                  <p class="card-title m-0 p-0">
                                    {{ billingAddressData.name }}
                                  </p>
                                  <p class="m-0 p-0">{{ billingAddressData.email }}</p>
                                  <p class="m-0 p-0">{{ billingAddressData.address }}</p>
                                  <p class="m-0 p-0">
                                    {{ billingAddressData.city }} &nbsp;
                                    {{ billingAddressData.state }}-
                                    {{ billingAddressData.pincode }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <h5 class="purpleText">Shipping address</h5>
                            <div class="col-md-12">
                              <div class="card AddressCard">
                                <div class="card-body">
                                  <p class="card-title m-0 p-0">
                                    {{ shippingAddressData.name }}
                                  </p>
                                  <p class="m-0 p-0">{{ shippingAddressData.email }}</p>
                                  <p class="m-0 p-0">{{ shippingAddressData.address }}</p>
                                  <p class="m-0 p-0">
                                    {{ shippingAddressData.city }} &nbsp;
                                    {{ shippingAddressData.state }}-
                                    {{ shippingAddressData.pincode }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="mt-3">
                            <section class="floor-plan-container">
                              <span class="blue-dot"></span>
                              <span class="text-decoration-underline">Floor Plan</span>
                            </section>
                            <span
                              v-if="leadData['awsFiles.awsFileName']"
                              @click="
                                $viewFile(
                                  leadData['awsFiles.awsFileName'],
                                  leadData['awsFiles.fileName'],
                                  'floorPlan',
                                  'sales'
                                )
                              "
                              style="
                                min-width: 300px;
                                max-width: fit-content;
                                background-color: #f8f8f8;
                                font-weight: 500;
                                padding: 10px;
                                margin: 5px 5px;
                                display: flex;
                                border-radius: 5px;
                                border: 1px solid #2381f3;
                                cursor: pointer;
                              "
                            >
                              <!-- Display PDF image if the file type is PDF -->
                              <div>
                                <img
                                  src="../../assets/icons/pdfImage.png"
                                  alt=""
                                  style="width: 50px; height: 50px"
                                />
                              </div>
                              <a
                                class="ms-2 mt-1"
                                target="_blank"
                                style="
                                  color: inherit; /* Inherit the color from the parent */
                                  text-decoration: none;
                                "
                                >{{ leadData["awsFiles.fileName"] }}</a
                              >
                              <!-- Download button aligned to the right -->
                              <div
                                style="margin-left: auto"
                                class="d-flex flex-column"
                              ></div>
                            </span>
                          </div>
                          <div class="mt-3" v-if="leadData['document.awsFileName']">
                            <section class="floor-plan-container">
                              <span class="blue-dot"></span>
                              <span class="text-decoration-underline">Lead Document</span>
                            </section>
                            <span
                              v-if="leadData['awsFiles.awsFileName']"
                              @click="
                                $viewFile(
                                  leadData['document.awsFileName'],
                                  leadData['document.fileName'],
                                  'leadDocument',
                                  'sales'
                                )
                              "
                              style="
                                min-width: 300px;
                                max-width: fit-content;
                                background-color: #f8f8f8;
                                font-weight: 500;
                                padding: 10px;
                                margin: 5px 5px;
                                display: flex;
                                border-radius: 5px;
                                border: 1px solid #2381f3;
                                cursor: pointer;
                              "
                            >
                              <!-- Display PDF image if the file type is PDF -->
                              <div>
                                <img
                                  src="../../assets/icons/pdfImage.png"
                                  alt=""
                                  style="width: 50px; height: 50px"
                                />
                              </div>
                              <a
                                class="ms-2 mt-1"
                                target="_blank"
                                style="
                                  color: inherit; /* Inherit the color from the parent */
                                  text-decoration: none;
                                "
                                >{{ leadData["document.fileName"] }}</a
                              >
                              <!-- Download button aligned to the right -->
                              <div
                                style="margin-left: auto"
                                class="d-flex flex-column"
                              ></div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-show="showEstimate" class="sectionAddress row">
                    <div class="table-responsive text-nowrap">
                      <table class="table tableFormat">
                        <caption class="d-none">
                          lead Estimates
                        </caption>
                        <thead>
                          <tr class="text-center">
                            <th style="background-color: #f4f4f4">SL. No.</th>
                            <th style="background-color: #f4f4f4">Client Name</th>
                            <th style="background-color: #f4f4f4">Date</th>
                            <th style="background-color: #f4f4f4">Estimate No</th>
                            <th style="background-color: #f4f4f4">Status</th>
                            <th style="background-color: #f4f4f4">Amount</th>
                          </tr>
                        </thead>
                        <tbody v-for="(item, index) in estimateData" :key="index">
                          <tr @click="OpenEstimate(item)">
                            <td class="text-center">{{ index + 1 }}</td>
                            <td data-bs-dismiss="modal" aria-label="Close">
                              {{ item.clientName }}
                            </td>
                            <td class="text-center">{{ item.date }}</td>
                            <td class="text-center">{{ item.estimateId }}</td>
                            <td class="text-right">{{ item.status }}</td>
                            <td class="text-right" style="text-align: right">
                              {{ $formatToIndianCurrency(item.grandTotal) }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div v-show="showAllActivity" class="sectionComments">
                    <ol>
                      <li
                        v-for="(activity, index) in leadActivities"
                        :key="index"
                        class="d-flex align-content-center position-relative"
                      >
                        <img
                          :src="activity['employees.signedUrl']"
                          alt=""
                          v-if="activity['employees.signedUrl']"
                          style="
                            width: 30px;
                            height: 30px;
                            margin-right: 5px;
                            z-index: 2;
                            border: 2px solid #ccc;
                            border-radius: 50%;
                          "
                          class="mt-2 UserProfilePicture"
                        />

                        <img
                          src="../../assets/icons/profile.png"
                          alt=""
                          v-else
                          style="width: 20px; height: 20px; margin-right: 5px; z-index: 2"
                          class="m-2 mt-2"
                        />

                        <div class="d-flex align-content-center flex-column m-2">
                          <span style="font-size: 12px; font-weight: 500"
                            >{{ activity["employees.firstName"] }}
                            {{ activity["employees.lastName"] }} {{ activity.text }}</span
                          >

                          <!-- Display formatted date -->
                          <span
                            style="font-size: 10px; word-spacing: 20px"
                            class="text-muted"
                            >{{ activity.created_at }}</span
                          >
                        </div>
                      </li>
                    </ol>
                  </div>
                  <div v-show="showAllComments" class="sectionComments">
                    <div>
                      <div v-show="showComments" class="sectionComments">
                        <section class="d-flex flex-column mt-2">
                          <textarea
                            name="leadComments"
                            id="leadComments"
                            class="form-control mb-2"
                            cols="30"
                            rows="5"
                            placeholder="Add Text....."
                            v-model="this.leadComment"
                          ></textarea>
                          <button
                            class="browse align-self-end"
                            @click="sendLeadComment()"
                          >
                            Done
                          </button>
                        </section>
                      </div>
                    </div>
                    <div v-show="showOtherDetails" class="sectionOther col-md-12">
                      <div class="row">
                        <div
                          class="col-md-12"
                          style="word-break: break-all; white-space: pre-wrap"
                        >
                          <ol>
                            <li
                              v-for="(comment, index) in leadComments"
                              :key="index"
                              class="mt-3"
                            >
                              <span
                                style="
                                  font-size: 14px;
                                  word-break: break-word;
                                  white-space: pre-wrap;
                                "
                              >
                                {{ comment.comments }}
                              </span>
                              <div
                                v-if="comment.comments"
                                class="text-muted d-flex align-items-center col-md-6"
                                style="font-size: 12px; letter-spacing: 2px"
                              >
                                <span>{{ comment.created_at }}</span>

                                <!-- Add margin between elements -->
                                <span
                                  v-if="comment['employees.firstName']"
                                  style="
                                    width: 5px;
                                    height: 5px;
                                    background-color: #d3d3d3;
                                    border-radius: 50%;
                                    display: inline-block;
                                    margin: 0 8px; /* Add margin for spacing */
                                  "
                                ></span>

                                <span style="font-size: 12px; letter-spacing: 2px">
                                  {{ comment["employees.firstName"] }}
                                  {{ comment["employees.lastName"] }}
                                </span>
                              </div>
                            </li>
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <!-- Modal body content goes here -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreateLeadModal from "./CreateLeadModal.vue";
import axios from "axios";
export default {
  name: "LeadDetailsModal",
  props: {
    selectedLeadId: {
      type: String, // Change the type according to your ID data type
      required: true,
    },
  },
  components: {
    CreateLeadModal,
    /*   SoldModal */
  },
  data() {
    return {
      EmployeeProfile: null,
      transactionDetails: "",
      showTransactionDetails: false,
      actions: [
        "Claimed",
        "RNR",
        "ContactMode",
        "Meeting",
        "Estimate",
        "Negotiation",
        "Sold",
        "Lost",
        "Trash",
      ],
      leadActivities: [], // Store lead activities
      showOverview: true,
      showEstimate: false,
      showAllActivity: false,
      showAllComments: false,
      showOtherDetails: true,
      showAddressDetails: true,
      showComments: true,
      leadComment: "",
      leadComments: [],
      estimateData: [],
      SelectedLeadId: this.selectedLeadId,
      leadData: {}, // To store lead details
      billingAddressData: {}, // To store billing address
      shippingAddressData: {}, // To store shipping address
    };
  },
  mounted() {
    if (this.selectedLeadId) {
      this.fetchLeadDetails();
    }
    this.fetchLeadActivity();
    this.fetchEstimate();
  },
  watch: {
    selectedLeadId(newValue) {
      if (newValue) {
        this.fetchLeadDetails();
        this.fetchLeadComments();
      }
    },
  },
  computed: {
    priorityButtonClass() {
      // Determine which class to apply based on leadData.priority
      switch (this.leadData.priority) {
        case "Low":
          return "btn-priority-low"; // Define this class in your CSS
        case "Medium":
          return "btn-priority-medium"; // Define this class in your CSS
        case "High":
          return "btn-priority-high"; // Define this class in your CSS
        default:
          return ""; // Default class if no priority is set or unknown priority
      }
    },
  },
  methods: {
    getDownloadFilename(url) {
      return url;
    },
    OpenEstimate(estimate) {
      const estimateId = estimate?.estimateId;
      const status = estimate?.status;

      if (estimateId) {
        if (status === "Draft" || status === "NULL" || status === "") {
          this.$router.push({
            name: "CreateEstimate",
            params: {
              EstimateId: estimateId,
              estimateIndex: estimate.id,
              // Additional params can be added here
            },
          });
        } else if (status === "Sent" || status === "Approved" || status === "Finalized") {
          this.$router.push({
            name: "FinalEstimate",
            params: {
              EstimateId: estimateId,
              estimateIndex: estimate.id,
              // Additional params can be added here
            },
          });
        } else {
          this.$handleError("Invalid status for routing");
          // Handle the scenario for other status values
        }
      } else {
        this.$handleError("Estimate or estimateId is undefined");
        // Handle the scenario where estimate or estimateId is undefined
      }
    },

    fetchLeadComments() {
      axios
        .get(`${this.$apiEndPoint}/leadsComments/${this.selectedLeadId}`)
        .then((response) => {
          this.leadComments = response.data; // Assuming the API returns the comments as an array under the key 'comments'
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    async sendLeadComment() {
      const currentDateTime = new Date();
      const formattedDateTime =
        currentDateTime
          .toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })
          .replace(/\s/g, "") +
        " " +
        currentDateTime.toLocaleDateString("en-GB").replace(/\//g, "-");

      const payload = {
        leadId: this.selectedLeadId,
        comments: this.leadComment,
        created_at: formattedDateTime,
        "employees.firstName": this.$LocalUser.user.firstName,
        "employees.lastName": this.$LocalUser.user.lastName,
      };

      this.tempComment = payload; // Define this.tempComment before the try block

      try {
        this.leadComment = "";
        // Store comment temporarily in case the API call fails
        this.leadComments.unshift(this.tempComment); // Optimistically add comment as the first item

        await axios.post(`${this.$apiEndPoint}/leadsComments`, payload);

        // Clear the input field if API call is successful
        this.fetchLeadComments();
        this.fetchLeadActivity();
      } catch (error) {
        this.$handleError(error);

        this.leadComments.shift(this.tempComment);

        if (this.tempComment) {
          this.leadComment = this.tempComment.comments;
        }
      }
    },

    changeStatus(selectedAction, EstimateApprovalCount) {
      if (selectedAction === "Sold") {
        if (EstimateApprovalCount > 0) {
          this.showTransactionDetails = true;
        } else {
          alert("To Sold Lead  The Approved Estimate of Lead Should Prasent");
        }
      } else {
        const payload = {
          status: selectedAction,
        };

        axios
          .put(`${this.$apiEndPoint}/leads/changeStatus/${this.selectedLeadId}`, payload)
          .then(() => {
            // Assuming you want to update the local data, update the lead status
            this.leadData.status = selectedAction;

            this.fetchLeadActivity();
            this.fetchData();
          })
          .catch((error) => {
            this.$handleError(error);
          });
      }
    },
    showTransactionModal() {
      this.showTransactionDetails = true;
    },

    hideTransactionDetails() {
      this.showTransactionDetails = false;
    },
    updateLeadStatus() {
      if (this.transactionDetails.trim() !== "") {
        const payload = {
          status: "Sold",
          transactionDetails: this.transactionDetails,
        };

        axios
          .put(`${this.$apiEndPoint}/leads/changeStatus/${this.selectedLeadId}`, payload)
          .then(() => {
            this.leadData.status = this.selectedAction; // Update local data
            this.fetchLeadActivity();
            this.fetchLeadDetails();
          })
          .catch(() => {});

        this.hideTransactionDetails();
        this.fetchLeadDetails();
      }
    },
    async fetchLeadDetails() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/leads/getByLeadId/${this.selectedLeadId}`
        );
        this.leadData = response.data;
        this.fetchLeadAddresses(response.data.id);
        this.fetchEstimate();
        this.fetchLeadActivity();
      } catch (error) {
        this.$handleError(error);
      }
    },
    fetchLeadActivity() {
      axios
        .get(`${this.$apiEndPoint}/leadActivity/${this.selectedLeadId}`)
        .then((response) => {
          this.leadActivities = response.data;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    fetchEstimate() {
      axios
        .get(`${this.$apiEndPoint}/estimates/byLead/${this.selectedLeadId}`)
        .then((response) => {
          // Merge the response data with estimates based on lead id
          const mergedEstimates = response.data.map((estimate) => ({
            ...estimate,
            clientName: this.leadData.name, // Assign clientName from the lead
          }));
          this.estimateData = mergedEstimates;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    fetchLeadAddresses(leadId) {
      axios
        .get(`${this.$apiEndPoint}/leadsAddress/${leadId}`)
        .then((response) => {
          const addresses = response.data;
          const billingAddress = addresses.find((addr) => addr.addressType === "Billing");
          const shippingAddress = addresses.find(
            (addr) => addr.addressType === "Shipping"
          );
          if (billingAddress) {
            this.billingAddressData = {
              name: billingAddress.name,
              address: billingAddress.address,
              city: billingAddress.city,
              state: billingAddress.state,
              country: billingAddress.country,
              pincode: billingAddress.pincode,
              contactNo: billingAddress.contactNo,
              email: billingAddress.email,
            };
          }

          if (shippingAddress) {
            this.shippingAddressData = {
              name: shippingAddress.name,
              address: shippingAddress.address,
              city: shippingAddress.city,
              state: shippingAddress.state,
              country: shippingAddress.country,
              pincode: shippingAddress.pincode,
              contactNo: shippingAddress.contactNo,
              email: shippingAddress.email,
            };
          }
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    showDetails(section) {
      this.showOverview = false;
      this.showEstimate = false;
      this.showAllActivity = false;
      this.showAllComments = false;

      // Set the specific section to true based on the button clicked
      if (section === "Overview") {
        this.showOverview = true;
      } else if (section === "Estimate") {
        this.showEstimate = true;
      } else if (section === "AllActivity") {
        this.showAllActivity = true;
      } else if (section === "Comments") {
        this.showAllComments = true;
      }
    },
    async fetchData() {
      this.$emit("fetchData");
    },
    showOverviewDetails(section) {
      if (section === "address") {
        this.showAddressDetails = !this.showAddressDetails;
      } else if (section === "other") {
        this.showOtherDetails = !this.showOtherDetails;
      } else if (section === "comments") {
        this.showComments = !this.showComments;
      }
    },
  },
};
</script>

<style scoped>
.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #2381f3;
  position: absolute;
  top: 10px;
  right: 3px;
}

.dotted-line {
  position: absolute;
  left: 0; /* Adjust as needed */
  top: 0;
  right: 10px;
  bottom: 0;
  width: 1px; /* Width of the line */
  border-left: 1px dotted #2381f3; /* Dotted line style */
}

input:focus,
textarea:focus,
select:focus {
  outline: none; /* Remove default outline */
  box-shadow: none; /* Add a custom focus shadow */
}
.tableFormat td {
  border: 1px solid #edeae9;
}
.tableFormat th {
  font-size: 14px;
}

select {
  width: auto;
  outline: none;
  background-color: transparent;
  text-align: left;
  padding: 6px 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 12px;
  margin: 0 8px 8px 0px; /* Add padding to the select */
}

/* Style the options within the dropdown */
select option {
  text-align: left;
  padding: 8px; /* Add padding to each option */
  margin-bottom: 5px; /* Add margin between options */
  background-color: #ffffff; /* Optional: Background color for options */
  color: #333333; /* Optional: Text color for options */
}

#transactionDetails {
  border: 2px solid #ccc;
  outline: none;
  margin-top: 10px;
}
.transaction-details {
  position: fixed;
  top: 60%;
  left: 70%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  width: 400px;
  height: auto;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  text-decoration: underline;
}
.comment-marker {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: blue;
}

.btn-priority-low {
  background-color: #f4e8fb;
  color: #ba69e2;
}

.btn-priority-medium {
  background-color: #ecebec;
  color: #7e7882;
}

.btn-priority-high {
  background-color: #feabab;
  color: #fd0000;
}

.btn-priority-Normal {
  background-color: #eaf3f1;
  color: #4b9b86;
}

.LeadDetaisForm {
  min-width: 100%;
  margin-left: 24%;
  font-size: 16px;
  color: #000000;
  height: 100%;
  overflow-y: scroll;
}
.modal-content {
  height: 100%; /* Ensure modal content fills the height */
}

.AddressCard {
  height: 150px;
  background-color: #f4f4f4;
  border: none;
}
.subHeadingsOfLeads p {
  cursor: pointer;
}

.subHeadingsOfLeads p:not(:first-child) {
  margin: 0 5%;
}

.subHeadingsOfLeads p:first-child {
  margin-right: 5%;
}

.subHeadingsOfLead {
  display: flex;
  align-content: center;
}
.subHeadingsOfLead p {
  margin-bottom: 0 !important;
}
.subSectionOfLeads img {
  width: 10px;
}
/* .dotted-vertical-line {
  border-right: 2px dotted #000; 
  margin-right: 8px; /
  height: 100%; /* 

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
  top: 0;
  bottom: 0;
} */

@media (max-width: 767px) {
  .transaction-details {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    width: 300px;
    height: auto;
  }
  .headerbuttons {
    overflow-x: scroll;
  }

  .LeadDetaisForm {
    max-width: 100%;
    /* Full width on mobile */
    margin-left: 0;

    /* Reset margin for mobile */
  }

  .subHeadingsOfLeads p:nth-child(2) {
    margin: 0 3%;
  }
}
</style>

<!-- showOverviewDetails(section) {
  /*   this.showOtherDetails = false;
    this.showAddressDetails = false;
    this.showComments = false;  */
    if (section === "address") {
      this.showAddressDetails = !this.showAddressDetails;
    } else if (section === "other") {
      this.showOtherDetails = true;
    } else if (section === "comments") {
      this.showComments = true;
    }
  }, -->
