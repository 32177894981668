import Swal from 'sweetalert2';

export function showAlert(title, text, icon = 'info', confirmButtonText = 'OK', cancelButtonText = null) {
  return Swal.fire({
    title: title,                
    text: text,                  
    icon: icon,                  
    showCancelButton: !!cancelButtonText, // Show cancel button only if cancelButtonText is provided
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText || '' // If null, set an empty string to avoid issues
  });
}

