<template>
  <div>
    <Header />
    <div class="content-container">
      <Nav />
      <div class="container-child">
        <div class="stock-report">
          <header class="report-header">
            <div class="report-title">
              <h3>Master Ledger</h3>
              <p class="stock-name">
                Stock Name - <label style="font-size: 18px"> {{ productName }}</label>
              </p>
            </div>
            <div>
              <div class="report-date">
                <label for="fromYearSelect" class="report-label">Report:</label>
                <select
                  id="fromYearSelect"
                  v-model="fromYear"
                  @change="updateYearForApi()"
                  class="custom-select"
                >
                  <option
                    v-for="year in financialYears"
                    :key="year.fromYear"
                    :value="year.fromYear"
                  >
                    FY Apr {{ year.fromYear }} - Mar {{ year.toYear }}
                  </option>
                </select>
              </div>
            </div>

            <div class="export-buttons">
              <!-- Download Icon -->
              <div class="download-wrapper" @click.stop>
                <img
                  src="../../assets/icons/pinkDownold.png"
                  class="download-icon"
                  alt="Download"
                  @click="toggleDownloadOptions($event)"
                />
                <ul v-show="showDownloadOptions" class="download-options">
                  <li @click="exportToCSV()">Excel</li>
                  <!-- <li @click="downloadReport()">PDF</li> -->
                </ul>
              </div>

              <!-- Close Icon -->
              <img
                src="../../assets/icons/cancelIcon.png"
                class="close-icon"
                alt="Close"
                onclick="window.history.back()"
              />
            </div>
          </header>

          <table class="report-table">
            <thead>
              <tr>
                <th>Sl.No</th>
                <th>Store Name</th>
                <!-- <th>Opening Stock</th> -->
                <th>Inward Stock</th>
                <th>Consumed Stock</th>
                <th>Available Stock</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in productStock.rows" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ row["stores.storeName"] }}</td>

                <td>{{ row.totalInwardQuantity }}</td>
                <td>{{ row.totalStockConsumption }}</td>
                <td>{{ row.totalClosingStock }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="1">Grand Total</td>
                <td></td>
                <td>{{ productStock.totalInwardStock }}</td>
                <td>{{ productStock.totalConsumedStock }}</td>
                <td>{{ productStock.totalAvailableStock }}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <ReportDateForm @applyDateRange="handleDateRange" />
      <FinancialYearStock
        ref="FinancialYearStock"
        :stock="productStock"
        :storeName="storeName"
        :productName="productName"
        :fromYear="fromYear"
        :toYear="toYear"
        :fromMonth="fromMonth"
        :toMonth="toMonth"
      />
    </div>
  </div>
</template>

<script>
import FinancialYearStock from "../Modal/financialYearStock.vue";
import axios from "axios";
import Header from "../Header.vue";
import Nav from "../Nav.vue";

export default {
  data() {
    const currentMonth = new Date().getMonth() + 1; // Month is zero-based
    const currentYear = new Date().getFullYear();
    const companyRegisteredYear = parseInt(
      this.$LocalUser.user.companyRegisteredYear,
      10
    );
    const currentFinancialYear =
      currentMonth >= 4
        ? { fromYear: currentYear, toYear: currentYear + 1 }
        : { fromYear: currentYear - 1, toYear: currentYear };
    // Initialize the array to hold financial years
    const financialYears = [];

    // Loop through each financial year from the company's registered year to the current year
    for (
      let year = companyRegisteredYear;
      year <= currentFinancialYear.fromYear;
      year++
    ) {
      // Only include financial years up to the current financial year
      if (
        year < currentFinancialYear.fromYear ||
        year === currentFinancialYear.fromYear
      ) {
        financialYears.push({ fromYear: year, toYear: year + 1 });
      }
    }

    console.log(financialYears);

    return {
      allData: [],
      storeName: this.$route.params.storeName,
      productName: this.$route.params.productName,
      showDownloadOptions: false,
      productStock: [],
      selectedStoreId: "",
      productId: "",
      fromYear: currentFinancialYear.fromYear, // Default selection
      financialYears, // Available financial years
      apiRequestBody: { year: currentFinancialYear.fromYear }, // Default API body
      fromMonth: 4,
      toMonth: 3,
      searchString: "",
      // availableYears: this.generateAvailableYears(),
    };
  },

  components: {
    Header,
    Nav,
    FinancialYearStock,
  },

  mounted() {
    console.log(this.$route.params.productId);

    this.productName = this.$route.params.productName;
    this.productId = this.$route.params.productId;

    console.log("recieving product id is:", this.productId);
    this.updateYearForApi(this.productId);
    // this.fetchProductStock(this.productId);
  },

  created() {
    this.fromYear = this.fromYear || new Date().getFullYear().toString();
  },

  methods: {
    downloadReport() {
      this.$refs.FinancialYearStock.generatePDF();
    },

    exportToCSV() {
      if (!Array.isArray(this.productStock.rows) || this.productStock.rows.length === 0) {
        console.error("productStock is not a valid array.");
        return;
      }

      const allData = this.productStock.rows;

      // Extract only the fields you want to include in the CSV
      const filteredData = allData.map((data) => ({
        Store_Name: data["stores.storeName"] || "0",
        Inward_Qty: data.totalInwardQuantity || "0",
        Outward_Qty: data.outwardQuantity || "0",
        Stock_Consumption: data.totalStockConsumption || "0",
        Closing_Stock: data.totalClosingStock || "0",
      }));
      // Convert filtered data to CSV format
      const csvContent = this.convertToCSV(filteredData);

      // Trigger download
      this.downloadCSV(csvContent);
    },

    formatAddress(address) {
      // Replace newline characters with commas
      return address ? address.replace(/\n/g, ", ") : "N/A";
    },

    convertToCSV(data) {
      const headers = Object.keys(data[0]).join(",") + "\n";
      const csvData = data.map((item) => Object.values(item).join(",")).join("\n");
      return headers + csvData;
    },

    downloadCSV(csvContent) {
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "table_data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    toggleDownloadOptions(event) {
      event.stopPropagation(); // Prevent click event from propagating to document
      this.showDownloadOptions = !this.showDownloadOptions; // Toggle dropdown visibility
    },
    handleClickOutside(event) {
      // Close the dropdown if the click is outside of the dropdown
      const dropdown = this.$refs.dropdown;
      if (dropdown && !dropdown.contains(event.target)) {
        this.showDownloadOptions = false;
      }
    },

    updateToYear() {
      // Find the corresponding `toYear` based on the selected `fromYear`
      const selectedFinancialYear = this.financialYears.find(
        (year) => year.fromYear === this.fromYear
      );

      if (selectedFinancialYear) {
        this.selectedFinancialYear = selectedFinancialYear;
      }
    },

    updateYearForApi() {
      console.log("Selected From Year:", this.fromYear);
      this.apiRequestBody = { year: this.fromYear };
      console.log("Updated API Request Body:", this.apiRequestBody);

      this.fetchProductStock(this.productId);
    },

    // async fetchProductStock(productId) {
    //   try {
    //     console.log("Fetching product stock for:", {
    //       productId,
    //       year: this.apiRequestBody.year,
    //     });

    //     const response = await axios.post(
    //       `${this.$apiEndPoint}/productStock/masterLedger/${productId}`,{

    //       }
    //     );

    //     console.log("API Response Data:", response.data);

    //     if (!response.data.rows || response.data.rows.length === 0) {
    //       console.warn("No data returned for the selected financial year.");
    //     }

    //     // Map and update product stock data with month names
    //     const updatedRows = response.data.rows.map((row) => ({
    //       ...row,
    //       monthName: new Date(0, row.month - 1).toLocaleString("en-US", {
    //         month: "long",
    //       }),
    //     }));

    //     this.productStock = {
    //       ...response.data,
    //       rows: updatedRows,
    //     };

    //     console.log("Updated Product Stock:", this.productStock);
    //   } catch (error) {
    //     console.error("Error fetching product stock:", error);
    //   }
    // },

    async fetchProductStock(productId) {
      try {
        // Log the request body to make sure it's correct
        console.log("Fetching product stock for:", {
          productId,
          year: this.apiRequestBody.year,
          searchString: this.searchString, // Add other parameters if needed
        });

        // Make the API request without manipulating month data
        const response = await axios.post(
          `${this.$apiEndPoint}/productStock/masterLedger/${productId}`,
          {
            year: this.apiRequestBody.year, // Send the year in the request body
            searchString: this.searchString, // Send any additional params
          }
        );

        console.log("API Response Data:", response.data);

        if (!response.data.rows || response.data.rows.length === 0) {
          console.warn("No data returned for the selected financial year.");
        }

        // Here, we're no longer mapping to convert months; just directly using the rows
        this.productStock = response.data;

        console.log("Product Stock:", this.productStock);
      } catch (error) {
        console.error("Error fetching product stock:", error);
      }
    },

    // async fetchProductStock(storeId, productId) {
    //   try {
    //     const requestBody = this.apiRequestBody; // Use the prepared request body
    //     console.log("Request Payload:", requestBody);

    //     const response = await axios.post(
    //       `${this.$apiEndPoint}/productStock/${storeId}/${productId}`,
    //       requestBody
    //     );

    //     console.log("Product Stock Data:", response.data.rows);

    //     const updatedRows = response.data.rows.map((row) => ({
    //       ...row,
    //       monthName: new Date(0, row.month - 1).toLocaleString("en-US", {
    //         month: "long",
    //       }),
    //     }));

    //     this.productStock = {
    //       ...response.data,
    //       rows: updatedRows,
    //     };

    //     console.log("Updated Product Stock:", this.productStock);
    //   } catch (error) {
    //     console.error("Error fetching product stock:", error);
    //   }
    // },
    // updateFY() {
    //   this.fetchProductStock(this.storeId, this.productId);
    // },
  },
};
</script>

<style scoped>
.report-date {
  display: flex;
  align-items: center;
  gap: 8px; /* Add spacing between elements */
  font-family: Arial, sans-serif;
  font-size: 14px;
}

.report-label {
  font-weight: bold;
}

.custom-select {
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  font-size: 14px;
  outline: none;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.custom-select:focus {
  border-color: #007bff;
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
}

.date-display {
  font-weight: bold;
  color: #333;
}

.content-container {
  display: flex;
  background-color: #fcfcfc;
  min-height: 100vh;
}

.container-child {
  flex: 1;
  padding: 10px;
  background-color: #bcbfc6;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-left: 20px;
}

.stock-report {
  font-family: Arial, sans-serif;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  min-height: 90vh;
  margin-left: 30px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden; /* Prevent horizontal overflow */
}

/* Header and export button styling */
.report-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0% 2%;
}

.report-title h3 {
  font-size: 18px;
  margin: 0;
  font-weight: 600;
}

.stock-name {
  font-size: 14px;
  color: #555;
  margin-top: 5px;
}

.report-date input {
  margin-bottom: 20px;
  padding: 2px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: small;
}

.export-buttons {
  display: flex;
  align-items: center;
  gap: 16px; /* Space between buttons */
}

.download-wrapper {
  position: relative; /* To position the dropdown relative to this container */
}

.download-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.download-icon:hover {
  transform: scale(1.1);
}

.download-options {
  position: absolute;
  top: 36px; /* Adjust based on the icon size */
  left: -10px; /* Adjust to the left (negative values shift it left) */
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  list-style: none;
  margin-right: 10%;
  padding: 0;
  z-index: 1000;
  width: 100px;
}

.download-options li {
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
}

.download-options li:hover {
  background-color: #017afc;
  color: #fff;
  font-size: small;
}

.close-icon {
  width: 22px;
  height: 22px;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.close-icon:hover {
  transform: rotate(90deg);
}
/* Table styling */
/* General Table Styling */
.report-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  margin-top: 0.5%;
  overflow: hidden; /* Prevents unwanted horizontal scroll */
}

/* Sticky Header Styling */
.report-table thead th {
  position: sticky;
  top: 0;
  background-color: #bcbfc6;
  color: #212529;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Adds shadow for separation */
}

/* Table Header, Data Cell Styling */
.report-table th,
.report-table td {
  text-align: left;
  padding: 8px;
  padding-left: 2%;
  font-size: 14px;
}

/* Sticky Footer Styling */
.report-table tfoot td {
  position: sticky;
  bottom: 0;
  background-color: #bcbfc6; /* Background color for footer */
  border-top: 3px solid #ababab;
  font-weight: 700;
}

/* Scrollable Table Body */
.report-table tbody {
  display: block;
  overflow-y: auto;
  height: 500px; /* Adjust the height to suit your needs */
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Row Styling */
.report-table tbody tr:nth-child(even) {
  background-color: #ededed;
}

.report-table tbody tr:nth-child(odd) {
  background-color: #fff;
}
/* Ensure correct display for all sections */
.report-table thead,
.report-table tfoot {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.report-table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
</style>
