<template>
  <ProcurementProductList @fetchData="fetchData" :selectedTargetId="SelectedTargetId" />
  <div>
    <Header />
    <div class="content-Container">
      <Nav />
      <div class="containerChild">
        <div class="FormatTable" v-if="showUnclaimedList">
          <div class="row headerOfTable d-flex">
            <div class="col-md-12 d-flex justify-content-between align-items-center">
              <!-- Left Content -->
              <div class="col-md-8 d-flex align-items-center">
                <h5 style="padding-top: 0%; padding-left: 1%">Master Ledger</h5>
              </div>

              <!-- Right Content -->
              <div class="col-md-4 d-flex justify-content-end align-items-center">
                <!-- Download Icon -->
                <div class="ImportExport mx-2" @click.stop>
                  <!-- Download Icon -->
                  <img
                    src="../../assets/icons/download.png"
                    alt="Download"
                    id="ImportIcon"
                    @click="toggleDropdown"
                  />

                  <!-- Dropdown Options -->
                  <ul v-show="showDropdown" class="dropdown-options" ref="dropdown">
                    <li @click="triggerDownload()">PDF</li>
                    <li @click="exportToCSV()">Excel</li>
                  </ul>
                </div>

                <!-- Search Box -->
                <div class="searchboxOfTable mx-2 d-flex align-items-center">
                  <div class="search-box d-flex align-items-center">
                    <img
                      src="../../assets/icons/search.png"
                      alt="Search Icon"
                      id="SearchIconOfTable"
                    />
                    <!-- <input
                        type="text"
                        name="search"
                        placeholder="Search"
                        v-model="searchString"
                      /> -->
                    <input
                      type="text"
                      class="search"
                      placeholder="Search"
                      style="
                        width: 200px;
                        border: none;
                        border-radius: 0;
                        font-size: small;
                      "
                      v-model="searchString"
                      @input="fetchData"
                    />
                  </div>
                  <img
                    src="../../assets/icons/cancel.png"
                    alt="Filter Icon"
                    id="FilterIconOfTable"
                    class="ms-2"
                    style="width: 20px; height: 20px; cursor: pointer; margin-top: 10px"
                    @click="ToStoreLedger"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="table-wrapper">
            <table class="tableFormat mb-3">
              <caption class="d-none">
                Table
              </caption>
              <thead>
                <tr>
                  <th class="text-center">Sl No.</th>
                  <th class="tableHeaderHeading text-left">Product Name</th>
                  <th class="tableHeaderHeading text-right">Available Stock</th>
                  <th class="tableHeaderHeading text-right">Unit</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(stock, index) in displayedData"
                  :key="index"
                  @click="goToStoreDetails(stock.productId)"
                >
                  <td class="text-center">{{ index + 1 }}</td>
                  <td class="text-left">{{ stock["productLists.productName"] }}</td>
                  <td class="text-left">{{ stock.totalClosingStock }}</td>
                  <td class="text-center">{{ stock["productLists.unitType"] }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="paginationOfTable">
            <div class="pageControlsTable">
              <img
                src="../../assets/icons/blueLeftarrow.png"
                @click="goToPreviousPage"
                :disabled="!hasPreviousPage"
                alt=""
              />
              <div class="page-numbers">
                <span
                  v-for="pageNumber in pagination"
                  :key="pageNumber"
                  :class="{
                    'page-number': true,
                    'current-page': pageNumber === currentPage,
                  }"
                  @click="goToPage(pageNumber)"
                  >{{ pageNumber }}</span
                >
              </div>

              <img
                src="../../assets/icons/blueRightarrow.png"
                @click="goToNextPage"
                :disabled="!hasNextPage"
                alt=""
              />
            </div>

            <div class="entries-info">
              <span class="entries-text">Entries: {{ displayedData.length }} Rows</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <StoreItemsPdf ref="childComp" :tableData="TableData" :storeName="storeName" />
</template>

<script>
import ProcurementProductList from "../Modal/ProcurementProductList.vue";
import Header from "../Header.vue";
import Nav from "../Nav.vue";
import axios from "axios";
import StoreItemsPdf from "../Modal/StoreItemsPdf.vue";

let pageFlag = true;
export default {
  name: "ProductMaster",
  components: {
    Header,
    Nav,
    ProcurementProductList,
    StoreItemsPdf,
  },
  data() {
    return {
      allData: [],
      showDropdown: false, // State for dropdown visibility
      storeStock: [],
      totalCount: null,
      totalArchiveCount: null,
      showBulkUploadModal: false,
      SelectedTargetId: null,
      EmployeeProfile: null,
      searchString: "",
      pageSize: 50,
      storeName: this.$route.params.storeName,
      currentPage: 1,
      showUnclaimedList: true,
      error: null,
      TableData: [],
      ArchiveData: [],
      selectedTeamId: null,
      employeeToDelete: null,
      selectedEmployeeId: null,
      data: {
        productList: [],
      },
    };
  },

  computed: {
    hasPreviousPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      return this.currentPage < Math.ceil(this.totalCount / this.pageSize);
    },
    hasArchivePreviousPage() {
      return this.currentPage > 1;
    },
    hasArchiveNextPage() {
      return this.currentPage < Math.ceil(this.totalArchiveCount / this.pageSize);
    },

    pagination() {
      const pageCount = Math.ceil(this.totalCount / this.pageSize);
      const maxVisiblePages = 5;
      let pages = [];

      if (pageCount <= maxVisiblePages) {
        pages = Array.from({ length: pageCount }, (_, index) => index + 1);
      } else {
        const startPage = Math.max(2, this.currentPage - Math.floor(maxVisiblePages / 2));
        const endPage = Math.min(
          pageCount - 1,
          this.currentPage + Math.floor(maxVisiblePages / 2)
        );

        pages = [1];

        if (startPage > 2) {
          pages.push("...");
        }

        pages.push(
          ...Array.from(
            { length: endPage - startPage + 1 },
            (_, index) => startPage + index
          )
        );

        if (endPage < pageCount - 1) {
          pages.push("...");
        }

        pages.push(pageCount);
      }

      return pages;
    },

    paginationArchive() {
      const pageCount = Math.ceil(this.totalArchiveCount / this.pageSize);
      const maxVisiblePages = 5;
      let pages = [];

      if (pageCount <= maxVisiblePages) {
        pages = Array.from({ length: pageCount }, (_, index) => index + 1);
      } else {
        const startPage = Math.max(2, this.currentPage - Math.floor(maxVisiblePages / 2));
        const endPage = Math.min(
          pageCount - 1,
          this.currentPage + Math.floor(maxVisiblePages / 2)
        );

        pages = [1];

        if (startPage > 2) {
          pages.push("...");
        }

        pages.push(
          ...Array.from(
            { length: endPage - startPage + 1 },
            (_, index) => startPage + index
          )
        );

        if (endPage < pageCount - 1) {
          pages.push("...");
        }

        pages.push(pageCount);
      }

      return pages;
    },
    displayedData() {
      return this.TableData;
    },
    displayedArchiveData() {
      return this.ArchiveData;
    },
  },

  mounted() {
    this.id = this.$route.params.id;
    this.storeName = this.$route.params.storeName;
    this.fetchData();
    document.addEventListener("click", this.handleClickOutside);
  },

  methods: {
    ToStoreLedger() {
      this.$router.push({
        name: "StoreLedger",
      });
    },

    toggleDropdown(event) {
      event.stopPropagation(); // Prevent the event from propagating to the document
      this.showDropdown = !this.showDropdown; // Toggle dropdown visibility
    },

    beforeDestroy() {
      document.removeEventListener("click", this.handleClickOutside);
    },

    handleClickOutside(event) {
      const dropdown = this.$refs.dropdown;
      if (dropdown && !dropdown.contains(event.target)) {
        this.showDropdown = false;
      }
    },

    triggerDownload() {
      // Log all references to check if the child component is available
      console.log(this.$refs);

      const childComponent = this.$refs.childComp;

      if (childComponent) {
        console.log("Child Component Found:", childComponent);
        if (typeof childComponent.downloadPDF === "function") {
          childComponent.downloadPDF();
        } else {
          console.error("downloadPDF method not found on child component.");
        }
      } else {
        console.error("Child component not found.");
      }
    },

    exportToCSV() {
      const allData = this.TableData;

      const filteredData = allData.map((data) => ({
        Product_Name: data["productLists.productName"] || "N/A",
        Closing_Stock: data.totalClosingStock || "N/A",
        Unit: data["productLists.unitType"] || "N/A",
      }));

      // Convert filtered data to CSV format
      const csvContent = this.convertToCSV(filteredData);

      // Trigger download
      this.downloadCSV(csvContent);
    },

    formatAddress(address) {
      // Replace newline characters with commas
      return address ? address.replace(/\n/g, ", ") : "N/A";
    },

    convertToCSV(data) {
      const headers = Object.keys(data[0]).join(",") + "\n";
      const csvData = data.map((item) => Object.values(item).join(",")).join("\n");
      return headers + csvData;
    },

    downloadCSV(csvContent) {
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "table_data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    goToStoreDetails(id) {
      console.log("Product ID:", id); // Check if ID is being passed correctly

      this.$router.push({
        name: "MasterLedgerProductReport",
        params: {
          productId: id,
        },
      });
    },

    async fetchStoreStock() {
      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/storeStock/${this.id}/${this.currentPage}`,
          { searchString: "" }
        );
        this.storeStock = response.data.rows;
        console.log("Store Details:", this.storeStock);
      } catch (error) {
        console.error("Error fetching store details:", error);
      }
    },

    closeModal() {
      // Close modal logic
      this.showBulkUploadModal = false;
      this.showUnclaimedList = true;
    },
    openModal(TargetId) {
      this.SelectedTargetId = TargetId;
    },
    downloadTemplate() {
      const link = document.createElement("a");
      link.href = "/Product_Master_Import_Template.csv"; // Path to the file in the public folder
      link.setAttribute("download", "Product_Master_Import_Template.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    goToPreviousPage() {
      if (this.hasPreviousPage) {
        this.changePage(-1); // Go to the previous page
      }
    },
    goToNextPage() {
      if (this.hasNextPage) {
        this.changePage(1); // Go to the next page
      }
    },
    goToArchivePreviousPage() {
      if (this.hasArchivePreviousPage) {
        this.changePage(-1); // Go to the previous page
      }
    },
    goToArchiveNextPage() {
      if (this.hasArchiveNextPage) {
        this.changePage(1); // Go to the next page
      }
    },

    async fetchData() {
      let searchData = {
        searchString: this.searchString,
      };
      const hasSearchData = searchData.searchString;
      if (hasSearchData && pageFlag === true) {
        this.currentPage = 1;
        pageFlag = false;
        console.log(pageFlag);
      }
      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/storeStock/masterLedger/${this.currentPage}`,
          searchData
        );
        this.totalCount = response.data.count;
        this.TableData = response.data.rows;
        console.log(`the fetched stock of store ID ${this.id}`, this.TableData);

        for (let product of this.TableData) {
          if (product["awsFiles.awsFileName"] && product["awsFiles.fileName"]) {
            const signedUrl = await this.fetchInfoImage(
              product["awsFiles.awsFileName"],
              product["awsFiles.fileName"]
            );
            if (signedUrl) {
              product.signedUrl = signedUrl;
            }
          }
        }

        console.log(this.TableData);
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
};
</script>

<style scoped>
.mainHeading {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.ImportExport {
  display: flex;
  align-items: center;
}

.searchboxOfTable {
  display: flex;
  align-items: left;
}

.search-box {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding-bottom: 15px;
  border: none;
}

.search-box input {
  border: none;
  border-bottom: 1px solid #ccc; /* Bottom border only */
  outline: none; /* Removes blue focus outline */
  font-size: 12px;
  margin-left: 5px;
  background-color: #ffffff; /* Removes background */
  width: 100px;
}

/* Additional styles for focus state */
.search-box input:focus {
  outline: none; /* Ensures no focus outline is visible */
}

.cursor-pointer {
  cursor: pointer;
}

#SearchIconOfTable,
#FilterIconOfTable {
  cursor: pointer;
  width: 16px;
  height: 14px;
}

.tableHeaderHeading {
  font-size: 14px;
}
.ActionImage img {
  padding: 5px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.ActionImage img:hover {
  background-color: #ccc;
  border-radius: 50%;
}

.status:hover {
  outline: 1px solid #ccc;
  padding: 1px;
  cursor: pointer;
}

.FormatTable {
  width: 100%;
  height: 100%;
}

.headerOfTable {
  width: 100%;
  padding: 1.5% 1%;
}

#addicon {
  width: 50px;
  height: 40px;
  margin: 5px 0 0 15px;
  outline: none;
}

#ImportIcon {
  width: 60%;
  margin-left: 9%;
  margin-top: 10px;
  background-color: inherit;
}

.dropdown-options {
  position: absolute;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  list-style: none;
  padding: 0;
  margin: 0; /* Remove margin for precise positioning */
  width: 150px;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  top: 350%; /* Position it below the triggering element */
  left: 0; /* Align with the left of the image */
  transform: translateY(-100%); /* Adjust to appear above the image */
}

.dropdown-options li {
  padding: 10px;
  cursor: pointer;
  text-align: left;
  font-size: 12px;
}

.dropdown-options li:hover {
  background-color: #4ea1fa;
  color: #fff;
}

.ImportExport {
  position: relative; /* For the dropdown to position relative to this container */
  display: inline-block;
}

#ArchiveIcon {
  width: 30%;
}

#SerchIconOfTable {
  width: 6%;
  margin-right: 5%;
}

.search-box {
  border-bottom: 2px solid #000;
  padding-bottom: 3px;
  display: flex;
  align-items: center;
}

.searchboxOfTable {
  width: 60%;
  display: flex;
  align-items: end;
}

#FilterIconOfTable {
  height: 8%;
  margin-left: 10%;
}

input[type="text"] {
  border: none;
  outline: none;
  font-size: 16px;
  width: 100%;
}

.table-wrapper {
  max-height: 71vh;
  overflow-y: auto;
  margin: 1% 1%;
}

/* Table styling */
.tableFormat {
  white-space: nowrap;
  max-height: 100%;
  width: 100%;
  overflow: scroll;
}

.tableFormat thead,
.tableFormat tbody {
  white-space: nowrap;
}

/* Sticky header */

.tableFormat thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

/* Scrollable body */
.tableFormat tbody {
  overflow: scroll;
}
/*
    .tableFormat tbody  tr td{
     border: 1px solid #ccc;
    }*/

.table-wrapper .tableFormat tbody tr:not(:first-child):nth-child(even) {
  background-color: #f8f8f8;
}

.table-wrapper .tableFormat tbody tr:not(:first-child):nth-child(odd) {
  background-color: #fff;
}

.dropdown-menu {
  z-index: 9999;
}

.tableFormat tbody tr td {
  padding: 0px 8px;
  height: auto;
}

.tableFormat thead tr th {
  background-color: #bcbfc6;
  color: #212529;
  padding: 5px 8px;
  height: auto;
}

.tableFormat tbody tr {
  height: 40px;
}

.tableFormat tbody td:nth-child(4) {
  width: 8%;
  text-align: left;
}

#blackThreeDots {
  width: 20px;
  height: 20px;
  margin-left: 30%;
  padding-bottom: 10%;
  position: relative;
  cursor: pointer;
}

#checkBox2 {
  width: 16px;
  height: 17px;
}

.page-numbers span {
  margin: 5px;
  cursor: pointer;

  /* Adjust color for page numbers */
}

.BoardHeader {
  display: flex;
  justify-content: space-between;
  /* Ensure items are spaced apart */
  align-items: center;
  /* Center items vertically */
  white-space: nowrap;
  /* Prevent wrapping */
}

.bolderHeadings {
  font-size: 16px;

  color: #000000;
  font-weight: bolder;
}

.tabledropdownEdits {
  position: absolute;
  display: inline-block;
  background-color: #ffffff;
  width: 150px;
  height: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin: 10px 20px;
  z-index: 9000;
  top: 60%;
}

.tabledropdownEdits p {
  padding: 4px 8px;
  text-decoration: none;
  display: flex;
  cursor: pointer;
  color: #000000;
}

.SigleLeadThings {
  position: absolute;
  display: inline-block;
  background-color: #ffffff;
  width: 150px;
  height: 240px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin: 10px 20px;
  z-index: 1000;
  top: 60%;
}

.SigleLeadThings p {
  padding: 4px 8px;
  text-decoration: none;
  display: flex;
  cursor: pointer;
  color: #000000;
}

@media (max-width: 768px) {
  #addicon {
    width: 25px;
    height: 25px;
    margin-left: 10px;
  }

  .table-wrapper {
    max-height: 90%;
    /* Adjust as needed */
    overflow-y: auto;
  }

  .headerOfTable {
    width: 100%;
    overflow: scroll;
  }

  #ArchiveIcon {
    width: 30%;
  }

  .ImportExport {
    display: none;
  }
  .paginationOfTable {
    bottom: 0.5%;
  }
}
</style>
