<template>
  <CreateEmployeeModal
    :employeeNumber="employeeDetails.id"
    @featchEmployeeProfileData="featchEmployeeData(employeeDetails.id)"
  />
  <Header />
  <!-- Pass employeeId as prop to CreateEmployeeModal -->
  <div class="content-Container">
    <Nav />
    <div class="containerChild">
      <div class="col-md-12 EmployeeProfile">
        <div class="col-md-4" style="background-color: #ffffff; font-size: 14px">
          <div class="row d-flex justify-content-center align-content-center mt-4">
            <div class="EmployeeprofileImage">
              <img
                v-if="EmployeeprofileImage"
                :src="EmployeeprofileImage"
                alt="Profile Photo"
                class="uploaded-photo"
              />
              <input type="file" id="EmployeeprofileImage" @change="handleFileUpload" />
            </div>

            <div class="text-center mt-2">
              <h6>{{ employeeDetails.firstName }} {{ employeeDetails.lastName }}</h6>
            </div>
            <div class="text-center mt-2">
              <button
                type="button"
                class="btn btn-danger btn-sm"
                v-if="employeeDetails.status === 'Inactive'"
              >
                Inactive
              </button>
              <button type="button" class="btn btn-success btn-sm" v-else>Active</button>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="ms-3">
              <div class="mb-3">
                <span class="text-muted">Employer</span> <br />
                <span>{{ employeeDetails["company.companyName"] }}</span>
              </div>
              <div class="mb-3">
                <span class="text-muted"> Employee ID</span><br />
                <span>{{ employeeDetails.employeeNumber }}</span>
              </div>
              <div class="mb-3">
                <span class="text-muted"> Phone Number</span><br />
                <span>{{ employeeDetails.mobileNumber }}</span>
              </div>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="ms-3">
              <div class="mb-3">
                <span class="text-muted">Date of Birth</span> <br />
                <span>{{ this.$formatDateDMY(employeeDetails.dob) }}</span>
              </div>
              <div class="mb-3">
                <span class="text-muted">Govt ID</span><br />
                <span>{{ employeeDetails.govtIdNo }}</span>
              </div>
              <div class="mb-3">
                <span class="text-muted"> Local Address</span><br />
                <span>{{ employeeDetails["addresses.address"] }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8 ms-2">
          <div
            class="d-flex justify-content-end align-content-center p-2"
            style="margin-right: 10px"
          >
            <div style="margin: 0 10px">
              <button
                class="browse"
                data-bs-toggle="modal"
                data-bs-target="#CreateEmployee"
                data-bs-whatever="@mdo"
              >
                Edit Profile
              </button>
            </div>
            <div class="mt-1 close">
              <img
                src="../assets/icons/cancel.png"
                alt=""
                @click="goBack"
                style="width: 20px; height: 20px; cursor: pointer"
              />
            </div>
          </div>

          <div class="profileInformation p-2" style="background-color: #ffffff">
            <div class="col-md-12 d-flex">
              <div class="col-md-3 redSubHeading">Employment Information</div>
              <div class="col-md-9">
                <div class="col-md-12 d-flex">
                  <!-- Date of Hire -->
                  <div class="mb-3 col-md-2 ContentBg">
                    <label for="dateOfHire" class="form-label text-muted"
                      >Date of Hire</label
                    >

                    <p>{{ this.$formatDateDMY(employeeDetails.dateOfHire) }}</p>
                  </div>
                  <!-- Official Email ID -->
                  <div class="mb-3 col-md-3 ContentBg">
                    <label for="officialEmailId" class="form-label text-muted"
                      >Official Email ID</label
                    >
                    <p>{{ employeeDetails.employeeEmailId }}</p>
                  </div>
                  <!-- Department -->
                  <div class="mb-3 col-md-3 ms-5 ContentBg">
                    <label for="department" class="form-label text-muted"
                      >Department (Roles)</label
                    >
                    <div
                      v-if="
                        employeeDetails.employeeRoles.some(
                          (role) => role.team === 'Admin' && role.role === 'Manager'
                        )
                      "
                    >
                      Business Owner
                    </div>
                    <div
                      v-else-if="
                        employeeDetails.employeeRoles.some(
                          (role) => role.team === 'Admin' && role.role === 'Executive'
                        )
                      "
                    >
                      Tool Admin
                    </div>
                    <!-- <span v-else>
                    <span v-for="role in userRoles" :key="role.team">
                      {{ role.team }}
                      <span style="font-size: 8px">({{ role.role }})</span>
                    </span>
                  </span> -->

                    <div
                      v-else
                      v-for="role in employeeDetails.employeeRoles"
                      :key="role.id"
                      class="team-role-item bodyText"
                    >
                      <p>{{ role.team }} ( {{ role.role }})</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="profileInformation p-2" style="background-color: #ffffff">
            <div class="col-md-12 d-flex">
              <div class="col-md-3 redSubHeading">Bank Information</div>
              <div
                class="col-md-9"
                v-if="bankDetails && bankDetails.accountNumber !== ''"
              >
                <div class="col-md-12 d-flex">
                  <!-- Date of Hire -->
                  <div class="mb-3 col-md-3 ContentBg">
                    <label for="dateOfHire" class="form-label text-muted">
                      Beneficiary Name</label
                    >
                    <p>{{ bankDetails.beneficiaryName }}</p>
                  </div>
                  <!-- Official Email ID -->
                  <div class="mb-3 col-md-4 ContentBg">
                    <label for="officialEmailId" class="form-label text-muted">
                      Bank Name</label
                    >
                    <p>{{ bankDetails.bankName }}</p>
                  </div>
                  <!-- Department -->
                  <div class="mb-3 col-md-4 ContentBg">
                    <label for="department" class="form-label text-muted">
                      Account Number</label
                    >
                    <p>{{ bankDetails.accountNumber }}</p>
                  </div>
                  <!-- Designation -->
                </div>
                <div class="col-md-10 d-flex">
                  <div class="mb-3 col-md-3 ContentBg">
                    <label for="designation" class="form-label text-muted">
                      IFSC Code</label
                    >
                    <p>{{ bankDetails.IFSC }}</p>
                  </div>
                  <!-- Date of Hire -->
                  <div class="mb-3 col-md-7 ContentBg">
                    <label for="dateOfHire" class="form-label text-muted">Address</label>
                    <p>{{ bankDetails.branchAddress }}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-9" v-else>
                <div class="col-md-12 d-flex">
                  <!-- Date of Hire -->
                  <div class="mb-3 col-md-3 ContentBg">
                    <label for="dateOfHire" class="form-label text-muted">
                      Beneficiary Name</label
                    >
                    <p></p>
                  </div>
                  <!-- Official Email ID -->
                  <div class="mb-3 col-md-4 ContentBg">
                    <label for="officialEmailId" class="form-label text-muted">
                      Bank Name</label
                    >
                    <p></p>
                  </div>
                  <!-- Department -->
                  <div class="mb-3 col-md-4 ContentBg">
                    <label for="department" class="form-label text-muted">
                      Account Number</label
                    >
                    <p></p>
                  </div>
                  <!-- Designation -->
                </div>
                <div class="col-md-10 d-flex">
                  <div class="mb-3 col-md-3 ContentBg">
                    <label for="designation" class="form-label text-muted">
                      IFSC Code</label
                    >
                    <p></p>
                  </div>
                  <!-- Date of Hire -->
                  <div class="mb-3 col-md-7 ContentBg">
                    <label for="dateOfHire" class="form-label text-muted">Address</label>
                    <p></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="profileInformation p-2" style="background-color: #ffffff">
            <div class="col-md-12 d-flex">
              <div class="col-md-3 redSubHeading">
                Emergency contact <br />
                Information
              </div>
              <div class="col-md-9">
                <div class="col-md-12 d-flex">
                  <!-- Date of Hire -->
                  <div class="mb-3 col-md-3 ContentBg">
                    <label for="dateOfHire" class="form-label text-muted">Name</label>
                    <p>{{ employeeDetails.emergencyContactName }}</p>
                  </div>
                  <!-- Official Email ID -->
                  <div class="mb-3 col-md-3 ContentBg">
                    <label for="officialEmailId" class="form-label text-muted">
                      Phone Number</label
                    >
                    <p>{{ employeeDetails.emergencyContactNumber }}</p>
                  </div>
                  <!-- Department -->
                  <div class="mb-3 col-md-5 ContentBg">
                    <label for="department" class="form-label text-muted">Address</label>
                    <p>{{ employeeDetails.emergencyContactAddress }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="profileInformation p-2" style="background-color: #ffffff">
            <div class="col-md-12 d-flex">
              <div class="col-md-3 redSubHeading">Skills</div>
              <div class="col-md-9">
                <div class="col-md-12 d-flex">
                  <!-- Date of Hire -->
                  <div class="mb-3 col-md-11 ContentBg">
                    <p>{{ employeeDetails.skills }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CreateEmployeeModal from "./Modal/CreateEmployeeModal.vue";
import Header from "./Header.vue";
import Nav from "./Nav.vue";
export default {
  name: "EmployeeProfile",
  components: {
    Header,
    Nav,
    CreateEmployeeModal,
  },
  data() {
    return {
      employeeNumber: "", // Define employeeId data property
      EmployeeprofileImage: null,
      employeeDetails: {
        id: "",
        employeeRoles: [],
      },
      bankDetails: {
        beneficiaryName: "",
        accountName: "",
        accountNumber: "",
        bankName: " ",
        IFSC: "",
        branchAddress: " ",
        entityId: null,
        entityName: "Employee",
      },
    };
  },
  mounted() {
    const employeeId = atob(this.$route.params.employeeId);
    this.featchEmployeeData(employeeId);
  },

  methods: {
    goBack() {
      this.$router.go(-1); // Navigate back one step in the history stack
    },
    featchEmployeeData(employeeId) {
      axios
        .get(`${this.$apiEndPoint}/employee/${employeeId}`)
        .then((response) => {
          this.employeeDetails = response.data;
          this.featchEmployeeBankDetails(employeeId);
          this.featchEmployeeProfile(employeeId);
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    featchEmployeeBankDetails(employeeId) {
      // Make API request to fetch employee details
      axios
        .get(`${this.$apiEndPoint}/bankDetails/byEntityId/${employeeId}/Employee/`)
        .then((response) => {
          this.bankDetails = response.data;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    featchEmployeeProfile(employeeId) {
      const PayLoad = {
        entityId: employeeId,
        fileCategory: "profile",
        moduleName: "company",
      };

      // Make API request to fetch employee profile photo URL
      axios
        .post(`${this.$apiEndPoint}/company/getFileSignedURL/`, PayLoad)
        .then((response) => {
          // Update profileImage with the received URL
          this.EmployeeprofileImage = Object.values(response.data)[0];
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    handleFileUpload(event) {
      const file = event.target.files[0];

      if (file) {
        // Read the file as a Data URL and assign it to profileImage
        this.EmployeeprofileImage = URL.createObjectURL(file);

        // Check if nextElementSibling exists before trying to access its style
        const nextSibling = event.target.nextElementSibling;
        if (nextSibling) {
          nextSibling.style.display = "none"; // Hide the label after image upload
        }
      }
    },
    formatDate(dateString) {
      if (!dateString) return "";
      const isYMDFormat = dateString.includes("-");
      if (isYMDFormat) {
        const [year, month, day] = dateString.split("-");
        return `${day}-${month}-${year}`; // Format as dd-mm-yyyy
      } else {
        // Handle other formats (e.g., dd/mm/yyyy, mm/dd/yyyy)
        // Try to parse the date string to a Date object
        const parsedDate = new Date(dateString);
        if (isNaN(parsedDate.getTime())) {
          return dateString;
        } else {
          // Format the parsed date as dd-mm-yyyy
          const day = parsedDate.getDate().toString().padStart(2, "0");
          const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0");
          const year = parsedDate.getFullYear();
          return `${day}-${month}-${year}`;
        }
      }
    },
  },
};
</script>
<style scoped>
.redSubHeading {
  color: #ff6b00;
  font-size: 12px;
}

/* Your existing styles */
/* Your existing styles */
.EmployeeProfile {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
}

.ContentBg {
  background-color: #f8f8f8;
  padding: 5px;
  border-radius: 10px;
  margin: 0 10px;
  height: fit-content;
}
.EmployeeprofileImage {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #b1c5df;

  position: relative; /* To position the input and label relative to the container */
}

.uploaded-photo {
  width: 100%;
  border: 2px solid #b1c5df;
  height: 100%;
  border-radius: 50%;
  position: absolute; /* Position the image absolutely within the container */
  top: 0;
  left: 0;
  display: inline-block;
  object-fit: cover;
  object-position: center;
  image-rendering: auto;
}

#EmployeeprofileImage {
  display: none;
}

label[for="EmployeeprofileImage"] {
  cursor: pointer; /* Change cursor to pointer on label */
  color: blue; /* Change color to indicate clickable */
  text-decoration: underline; /* Add underline to indicate clickable */
  position: absolute; /* Position the label absolutely within the container */
  top: 90%;
  left: 60%;
  transform: translate(-50%, -50%); /* Center the label horizontally and vertically */
}

.profileInformation label {
  font-size: 12px;
}

.profileInformation p {
  font-size: 12px;
}

@media (max-width: 767px) {
  .EmployeeProfile {
    display: flex;
    width: auto;
    height: auto;
    flex-direction: column;
    overflow: auto;
    margin-bottom: 50px;
    background-color: #ffffff;
  }
  .close {
    position: absolute;
    right: 5px;
    top: 0;
  }
}
</style>
