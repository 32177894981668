<template>
  <div class="EmployeesignupGroup">
    <div class="row d-flex align-items-center">
      <div class="col-md-7 col-sm-7 col-lg-7">
        <div class="loginImg d-flex justify-content-center">
          <img src="../assets/icons/loginImage.png" alt="" />
        </div>
      </div>
      <div class="col-md-5 col-sm-5 col-lg-5">
        <div class="login align-items-center">
          <div class="logo d-flex justify-content-center">
            <h1>ARIVU</h1>
          </div>
          
          <h5 class="d-flex justify-content-center" v-if="showCompanyDetails">
            <b>Company Setup</b>
          </h5>
          <div class="Employeesignup" v-if="showCompanyDetails">
            <span class="input-wrapper mt-4">
              <label for="companyName"> &nbsp;Company Name &nbsp;</label>
              <input type="text" v-model="companyDetails.companyName" />
            </span>
            <span class="input-wrapper mt-4">
              <label for="Email ID"> &nbsp;Email ID &nbsp;</label>
              <input type="text" v-model="companyDetails.companyEmail" />
            </span>
            <span class="input-wrapper mt-4">
              <label for="Phone Number"> &nbsp;Phone Number &nbsp;</label>
              <input type="text" v-model="companyDetails.companyPhoneNumber" />
            </span>
            <span class="input-wrapper mt-4">
              <label for="Location"> &nbsp;Location &nbsp;</label>

              <input type="text" v-model="companyDetails.companyLocation" />
            </span>

            <button
              id="resetSubmitButton"
              class="btn btn-sm btn-primary"
              :disabled="
                !this.companyDetails.companyName &&
                !companyDetails.companyEmail &&
                !companyDetails.companyPhoneNumber &&
                !companyDetails.companyLocation
              "
              @click="submitCompanyDetails"
            >
              Submit and Continue
            </button>
            <div class="signUp d-flex justify-content-center align-items-center">
              Already Registered? &nbsp;
              <router-link to="/login" style="text-decoration: none">SignIn</router-link>
            </div>
          </div>
          <h5 class="d-flex justify-content-center" v-if="showPersonalDetails">
            <b>Admin Account</b>
          </h5>

          <div class="Employeesignup" v-if="showPersonalDetails">
            <span class="input-wrapper mt-4">
              <label for="First Name"> &nbsp;First Name &nbsp;</label>
              <input type="text" v-model="personalDetails.firstName" />
            </span>
            <span class="input-wrapper mt-4">
              <label for="Last Name"> &nbsp;Last Name &nbsp;</label>
              <input type="text" v-model="personalDetails.lastName" />
            </span>
            <span class="input-wrapper mt-4">
              <label for="Email Id"> &nbsp;Email Id &nbsp;</label>
              <input type="text" v-model="personalDetails.personalEmail" />
            </span>
            <span class="input-wrapper mt-4">
              <label for="Phone Number"> &nbsp;Phone Number&nbsp;</label>
              <input type="text" v-model="personalDetails.personalPhoneNumber" />
            </span>
            <span class="input-wrapper mt-4">
              <label for="Password"> &nbsp;New Password &nbsp;</label>
              <input type="password" v-model="personalDetails.password" />
            </span>
            <button id="resetSubmitButton" @click="signUp" :disabled="submitDisabled">
              Submit
            </button>
            <div class="signUp d-flex justify-content-center align-items-center">
              Already Registered? &nbsp;
              <router-link to="/login" style="text-decoration: none">Sign In</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SignUp",
  data() {
    return {
      showCompanyDetails: true,
      showPersonalDetails: false,
      companyDetails: {
        companyName: "",
        companyEmail: "",
        companyPhoneNumber: "",
        companyLocation: "",
      },

      personalDetails: {
        firstName: "",
        lastName: "",
        personalEmail: "",
        personalPhoneNumber: "",
        password: "",
      },
      submitDisabled: false,
    };
  },

  watch: {
    "companyDetails.companyEmail"(newVal) {
      this.personalDetails.personalEmail = newVal;
    },
    "companyDetails.companyPhoneNumber"(newVal) {
      this.personalDetails.personalPhoneNumber = newVal;
    },
  },

  methods: {
    submitCompanyDetails() {
      // Check if all company details are filled
      if (
        this.companyDetails.companyName &&
        this.companyDetails.companyLocation &&
        this.companyDetails.companyPhoneNumber &&
        this.companyDetails.companyEmail
      ) {
        // Hide company details section and show personal details section
        this.showCompanyDetails = false;
        this.showPersonalDetails = true;
      } else {
        alert("Please fill all company details fields");
      }
    },

    async signUp() {
      this.submitDisabled = true; // Disable the button

      try {
        // Step 1: Create the company
        const companyResponse = await axios.post(`${this.$apiEndPoint}/company/`, {
          companyName: this.companyDetails.companyName,
          companyEmail: this.companyDetails.companyEmail,
          contactNumber: this.companyDetails.companyPhoneNumber,
          address: this.companyDetails.companyLocation,
        });

        if (companyResponse.status !== 201) {
          throw new Error("Failed to create company");
        }

        console.log("Company Created:", companyResponse.data);

        // Step 2: Create the user
        const userResponse = await axios.post(`${this.$apiEndPoint}/users/register`, {
          companyId: companyResponse.data.id,
          firstname: this.personalDetails.firstName,
          lastname: this.personalDetails.lastName,
          mobile: this.personalDetails.personalPhoneNumber,
          email: this.personalDetails.personalEmail,
          password: this.personalDetails.password,
        });

        if (userResponse.status === 201) {
          this.$showAlert("Success", "Created Successfully!", "success", "OK");

          // Store user data in localStorage
          // localStorage.setItem("user-info", JSON.stringify(userResponse.data));

          // Redirect to Login page
          this.$router.push({
            name: "Login",
            params: {
              email: this.personalDetails.personalEmail,
              password: this.personalDetails.password,
            },
          });
        }
      } catch (error) {
        console.error("Signup Error:", error);
        // this.$handleError(error);

        // Show an alert for the error
        this.$showAlert(
          "Error",
          `${error.response.data.message || "Failed to create user"}`,
          "error",
          "OK"
        );
      } finally {
        this.submitDisabled = false; // Re-enable button
      }
    },
  },
};
</script>

<!-- <style scoped>
.EmployeesignupGroup {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #084f5b;
}

#EmployeeSignupHeading {
  margin-top: 5%;
  font-size: 2rem;
}

.Employeesignup {
  display: flex;
  margin-top: 2%;
  flex-direction: column;
}

#EmployeeSignButton {
  padding: 8px;
  background-color: whitesmoke;
  color: black;
  font-size: 1rem;
  border-radius: 5px;
  outline: none;
  border: none;
  cursor: pointer;
  margin-top: 1%;
}

.EmployeeSignUpFooter {
  margin-top: 1.5%;
}

.EmployeeSignUpFooter a {
  color: #ffaa33;
  cursor: pointer;
}

.input-wrapper {
  min-width: 330px;
  height: 40px;
  position: relative;
  margin: 4%;
  right: 4%;
}

.input-wrapper input {
  padding: 6% 2%;
  height: 100%;
  width: 100%;
  z-index: 99;
  border: 1.5px solid black;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease, background-color 0.3s ease;
  background-color: #084f5b;
  color: white;
  font-weight: bolder;
  /* Set input text color */
  font-size: 1rem;
}

.input-wrapper label {
  white-space: nowrap;
  text-align: left;
  /* Adjusted to align left */
  position: absolute;
  margin-top: -10px;
  /* Adjusted to overlap with input when empty */
  left: 10px;
  pointer-events: none;
  z-index: 1;
  font-size: 1.1rem;
  transition: all 0.4s ease;
  color: white;
  font-weight: 500;
  background-color: #084f5b;
  padding: 0px 5px;
  border-radius: 5px;
}

.input-wrapper input:focus {
  border-color: #ffbd59;

}

.input-wrapper input:focus~label,
.input-wrapper input:not(:placeholder-shown)~label {
  margin-top: -20px;
  /* Adjusted to move label up */
  left: 10px;
  font-size: 16px;
  background-color: #084f5b;

}

.input-wrapper input:not(:focus):not(:placeholder-shown)~label {
  font-size: 1.1rem;
  margin-top: -10px;
  /* Adjusted to overlap with input when empty */
  left: 10px;
  background-color: #084f5b;
}

.EmployeesignupGroup p {
  color: #fff;
}

@media (max-width: 768px) {
  #EmployeeSignupHeading {
    margin-top: -10%;
  }

  .EmployeesignupGroup {
    width: 100%;
    height: 100%;
  }
}
</style> -->

<!-- 
<template>
<h1>UN<font color="#ffbd59">R</font>IDDLE</h1>
<h2>Sign Up</h2>

<div class="signup">
    Name
    <input type="text" ref="name" placeholder="" />
    Email
    <input type="text" ref="email" placeholder="" />
    Password
    <input type="text" ref="password" placeholder="" />
    <button v-on:click="signUp()">Sign Up</button>
    <p>
        <router-link to="/login">Login</router-link>
    </p>
</div>

</template>

<script>
import axios from 'axios'
export default {
name: 'SignUp',
data() {
    return {
        name : '',
        email : '',
        password : ''
    };
},
methods: {
    async signUp() {
        let result = await axios.post(`${this.$apiEndPoint}/users/register`, {
            name : this.$refs.name.value,
            email : this.$refs.email.value,
            password : this.$refs.password.value
        });

   

        if(result.status == 201) {
            alert("User Created Successfully!");
            localStorage.setItem("user-info", JSON.stringify(result.data));
            this.$router.push({name: 'Home'});
        }
    }
},
mounted() {
    let user = localStorage.getItem("user-info");
    if(user) {
        this.$router.push({name : 'Home'});
    }
}
}
</script>  -->

<!-- mahesh -->
<style scoped>
.toggle-password {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.loginImg img {
  max-width: 80%;
  max-height: 80%;
}

.logo h1 {
  margin-bottom: 8%;
}

.EmployeesignupGroup {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
}

#EmployeeSignupHeading {
  margin-top: 5%;
  font-size: 2rem;
}

.Employeesignup {
  display: flex;
  margin-top: 2%;
  flex-direction: column;
}

/* .Employeesignup p {
    color: #fff;
} */

#resetSubmitButton {
  margin: 10% 0%;
  margin-top: 10%;
  padding: 5px;
  background-color: #4884f7;
  color: #fff;
  font-size: 1rem;
  border-radius: 5px;
  outline: none;
  border: none;
  cursor: pointer;
}

.signUp {
  margin-top: -5%;
}

.EmployeeSignUpFooter a {
  color: #4884f7;
  cursor: pointer;
}

.input-wrapper {
  min-width: 400px;
  height: 40px;
  position: relative;
}

/* .input-wrapper p {
  margin-left: 55%;
  margin-top: 2%;
} */

.input-wrapper input {
  padding: 5% 2%;
  height: 100%;
  width: 100%;
  z-index: 99;
  border: 1.5px solid black;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease, background-color 0.3s ease;
  background-color: #ffffff;
  font-weight: bolder;
  /* Set input text color */
  font-size: 1rem;
}

.input-wrapper textarea {
  padding: 5% 2%;
  height: 100%;
  width: 100%;
  z-index: 99;
  border: 1.5px solid black;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease, background-color 0.3s ease;
  background-color: #ffffff;
  font-weight: bolder;
  /* Set input text color */
  font-size: 1rem;
}

.input-wrapper label {
  white-space: nowrap;
  text-align: left;
  /* Adjusted to align left */
  position: absolute;
  margin-top: -14px;
  /* Adjusted to overlap with input when empty */
  left: 10px;
  pointer-events: none;
  z-index: 1;
  font-size: 1rem;
  transition: all 0.4s ease;
  color: #000000;
  font-weight: 500;
  background-color: #ffffff;
}

.input-wrapper input:focus {
  border-color: #4884f7;
}

.input-wrapper input:focus ~ label,
.input-wrapper input:not(:placeholder-shown) ~ label {
  margin-top: -20px;
  /* Adjusted to move label up */
  left: 10px;
  font-size: 16px;
  background-color: #084f5b;
}

.input-wrapper input:not(:focus):not(:placeholder-shown) ~ label {
  font-size: 1.1rem;
  margin-top: -10px;
  /* Adjusted to overlap with input when empty */
  left: 10px;
  background-color: #084f5b;
}

@media (max-width: 768px) {
  .loginImg img {
    display: none;
  }

  .input-wrapper {
    min-width: 320px;
    height: 40px;
    position: relative;
  }

  .EmployeesignupGroup h2 {
    margin-bottom: 10%;
  }

  #EmployeeSignupHeading {
    margin-top: -40%;
    font-size: 1.5rem;
  }
}
</style>
